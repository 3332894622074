import { AuthService } from '@/core/services/auth.service';
import { MessageType, ToastService } from '@/core/services/toast.service';
import { catchError, Observable, switchMap, EMPTY } from 'rxjs';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {
  private _isTokenRefreshing = false;

  constructor(
    private toastService: ToastService,
    private authService: AuthService,
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if(error.error !== null) {
          if(error.status === 401) {
            if(!this._isTokenRefreshing) {
              this._isTokenRefreshing = true;
              if(this.authService.isLoggedIn()) {
                return this.authService.refreshToken$().pipe(
                  switchMap(() => {
                    this._isTokenRefreshing = false;
                    req = req.clone({
                        setHeaders: { Authorization: `Bearer ${this.authService.getAuthToken()}` }
                    });
                    return next.handle(req);
                  }),
                  catchError(() => {
                    this._isTokenRefreshing = false;
                    this.authService.logout();
                    return EMPTY;
                  })
                )
              } else {
                this.toastService.raiseMessage(`${error.error.errors[0].message}`, MessageType.Error);
              }
            } else {
              return next.handle(req);
            }
          } else {
            this.toastService.raiseMessage(`${error.error.errors[0].message}`, MessageType.Error);
          }
        } else {
          this.toastService.raiseMessage(`Error ${error.status}: ${error.statusText}`, MessageType.Error)
        }
        return EMPTY;
      })
    );
  }
}

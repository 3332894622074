import { Component } from "@angular/core";
import { RippleAnimationConfig } from "@angular/material/core";

export interface NavbarItem {
  name: string;
  icon: string;
  path: string;
}

@Component({
  selector: "cms-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent {

  public navbarItems: NavbarItem[] = [
    { name: "Users", icon: "groups", path: "/users" },
    { name: "Experiences", icon: "event", path: "/experiences" },
    { name: "Happenings", icon: "star", path: "/happenings" },
  ];

  public rippleAnimation: RippleAnimationConfig = {
    enterDuration: 500,
    exitDuration: 0,
  };

}

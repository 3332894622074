<div class="cms-login__container frugocms-dark-theme">
  <div class="cms-login__title-container">
    <h1 class="cms-login__header__primary">Admin CMS</h1>
  </div>
  <div 
    [formGroup]="_loginForm" 
    class="cms-login__form__container" 
  >
    <p class="text-center">Enter your email and password</p>
    <mat-form-field 
      appearance="outline"
      class="cms-login__form__field" 
    >
      <mat-label>Email:</mat-label>
      <input
        matInput
        (keydown.enter)="onLogin()"
        formControlName="email"
        type="email"
        placeholder="Ex. pat@example.com"
        class="cms-login__form__input"
      />
      <mat-error *ngIf="_emailFormControl.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field 
      class="cms-login__form__field" 
      appearance="outline"
    >
      <mat-label>Password:</mat-label>
      <input 
        matInput 
        (keydown.enter)="onLogin()"
        formControlName="password" 
        type="password" 
      />
      <mat-error *ngIf="_passwordFormControl.hasError('required')">
        Password is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <button 
      mat-raised-button 
      (click)="onLogin()" 
      class="cms-login__button"
    >
      Login
    </button>
  </div>
  <div class="cms-login__logo__container">
    <img
      src="./assets/logo/thrugo-logo.gif"
      alt="thrugo logo"
      class="cms-login__logo"
    />
    <div class="cms-login__logo__text">
      <h2 class="cms-login__header__secondary">thrugo</h2>
    </div>
  </div>
</div>

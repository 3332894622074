import { MatIconModule } from '@angular/material/icon';
import { CmsRootLayoutComponent } from "./components/root-layout/root-layout.component";
import { CommonModule } from "@angular/common";
import { CoreRoutingModule } from "./routing/core-routing.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { JwtInterceptor } from "@/core/interceptors/jwt.interceptor";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRippleModule } from "@angular/material/core";
import { MatTableModule } from "@angular/material/table";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TopbarComponent } from "./components/topbar/topbar.component";
import { HttpErrorInterceptorService } from "./interceptors/http-error-interceptor.service";
import { MatSnackBarModule } from "@angular/material/snack-bar";

@NgModule({
  declarations: [TopbarComponent, NavbarComponent, CmsRootLayoutComponent, ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    MatDividerModule,
    MatListModule,
    MatRippleModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatSnackBarModule,
    MatIconModule
  ],
  exports: [
    RouterModule,
    TopbarComponent,
    CmsRootLayoutComponent,
    NavbarComponent,
  ],
  providers: [
    HttpErrorInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    }, 
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: HttpErrorInterceptorService,
      multi: true,
    }, 
  ],
})
export class CoreModule {
}

<div class="cms-navbar__container">
  <div class="cms-navbar__logo__container">
    <img
      src="./assets/logo/thrugo-logo.gif"
      alt="thrugo logo"
      class="cms-navbar__logo"
    />
  </div>
  <mat-selection-list 
    [multiple]="false"
    class="cms-navbar__menu__container" 
  >
    <mat-list-option
      *ngFor="let item of navbarItems"
      matRipple
      [matRippleColor]="'rgba(0, 0, 0, 0.1)'"
      [matRippleRadius]="200"
      [matRippleAnimation]="rippleAnimation"
      [routerLink]="[item.path]"
    >
      <div class="cms-navbar__menu__element">
        <div class="material-symbols-outlined">{{ item.icon }}</div>
        <div>{{ item.name }}</div>
      </div>
    </mat-list-option>
  </mat-selection-list>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from "@/core/services/auth.service";

@Injectable({
  providedIn: 'root',
})
export class UnauthenticatedGuard implements CanActivate {
  public constructor(
    private readonly _router: Router,
    private readonly _authService: AuthService,
  ) { 
  }

  public canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this._authService.isLoggedIn()){
      this._router.navigate([]);
      return false
    }
    return true;
  }
}

<div class="cms-topbar__wrapper">
  <div class="cms-topbar__title">
    <span class="cms-topbar__marginless">Thrugo Admin Panel</span>
  </div>
  <div class="cms-topbar__container">
    <button
      mat-flat-button
      (click)="onLogout()"
      class="cms-topbar__button"
    >
      Logout
      <mat-icon fontIcon="logout" class="cms-topbar__button__icon"></mat-icon>
    </button>
  </div>
</div>

import { AuthenticatedGuard } from "@/core/guards/authenticated.guard";
import { CmsRootLayoutComponent } from "@/core/components/root-layout/root-layout.component";
import { Route } from "@angular/router";
import { UnauthenticatedGuard } from "@/core/guards/unauthenticated.guard";

export const ROUTES: Route[] = [
  {
    path: "",
    component: CmsRootLayoutComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "users",
      },
      {
        path: "users",
        loadChildren: async () =>
          (await import("@/modules/users/users.module")).UsersModule,
      },
      {
        path: "experiences",
        loadChildren: async () =>
          (await import("@/modules/experiences/experiences.module")).ExperiencesModule,
      },
      {
        path: "happenings",
        loadChildren: async () =>
          (await import("@/modules/happenings/happenings.module")).HappeningsModule,
      },
    ],
  },
  {
    path: "login",
    loadChildren: async () =>
      (await import("@/modules/login/login.module")).LoginModule,
    canActivate: [UnauthenticatedGuard],
  },
];

import { AuthService } from "@/core/services/auth.service";
import { Component } from "@angular/core";

@Component({
  selector: "cms-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent {
  constructor(
    private readonly _authService: AuthService,
  ) {
  }

  public onLogout(): void {
    this._authService.logout();
  }
}

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ExperienceId: any;
  FrequentlyAskedQuestionId: any;
  IdentityId: any;
  /** Represents an instant on the global timeline. */
  Instant: any;
};

/** Activity description */
export type Activity = {
  __typename?: 'Activity';
  /** happening details */
  happening: Happening;
  /** Id */
  id: Scalars['ID'];
  /** Name */
  name: Scalars['String'];
  /** Thumbnail url */
  thumbnailUrl: Scalars['String'];
  /** Tickets */
  tickets: Array<Ticket>;
  /** Activity url */
  url: Scalars['String'];
};

export type AuthMutationResponse = {
  __typename?: 'AuthMutationResponse';
  /** Access Token */
  accessToken: Scalars['String'];
  chatUserToken: Scalars['String'];
  identity: Identity;
  /** Refresh Token */
  refreshToken: Scalars['String'];
};

export type BanInput = {
  identityId: Scalars['IdentityId'];
};

export type ContentMutationGroup = {
  __typename?: 'ContentMutationGroup';
  createFrequentlyAskedQuestion: MutationIdResponse;
  deleteFrequentlyAskedQuestion: MutationResponse;
  updateFrequentlyAskedQuestion: MutationResponse;
};


export type ContentMutationGroupCreateFrequentlyAskedQuestionArgs = {
  input: CreateFrequentlyAskedQuestionsInput;
};


export type ContentMutationGroupDeleteFrequentlyAskedQuestionArgs = {
  input: DeleteFrequentlyAskedQuestionsInput;
};


export type ContentMutationGroupUpdateFrequentlyAskedQuestionArgs = {
  input: UpdateFrequentlyAskedQuestionsInput;
};

export type CreateFrequentlyAskedQuestionsInput = {
  answer: Scalars['String'];
  question: Scalars['String'];
};

export type DeleteFrequentlyAskedQuestionsInput = {
  id: Scalars['FrequentlyAskedQuestionId'];
};

export type DeleteInput = {
  identityId: Scalars['IdentityId'];
};

/** Experience description */
export type Experience = {
  __typename?: 'Experience';
  /** Activity details */
  activity?: Maybe<Activity>;
  /** Created at */
  createdAt?: Maybe<Scalars['Instant']>;
  /** Id */
  id: Scalars['ID'];
  /** Invite Code */
  inviteCode: Scalars['String'];
  /** Members list */
  members: Array<Maybe<User>>;
  /** Name */
  name: Scalars['String'];
  /** Owner Id */
  ownerId: Scalars['ID'];
  /** Poll details */
  poll?: Maybe<Poll>;
  /** Status */
  status?: Maybe<Status>;
};

/** Count experiences */
export type ExperienceCount = {
  __typename?: 'ExperienceCount';
  /** Number of experiences */
  result: Scalars['Int'];
};

export type ExperienceCountFiltersInput = {
  status: PublicStatus;
};

export type ExperienceDeleteInput = {
  experienceId: Scalars['ExperienceId'];
};

/** Experience list */
export type ExperienceList = {
  __typename?: 'ExperienceList';
  /** Items */
  items: Array<Experience>;
  /** Meta */
  meta: ListMetadata;
};

export type ExperienceListFiltersInput = {
  searchTerm?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PublicStatus>;
};

export type ExperienceMutationGroup = {
  __typename?: 'ExperienceMutationGroup';
  delete: MutationResponse;
};


export type ExperienceMutationGroupDeleteArgs = {
  input: ExperienceDeleteInput;
};

export type ExperienceQueryGroup = {
  __typename?: 'ExperienceQueryGroup';
  count: ExperienceCount;
  getById: Experience;
  list: ExperienceList;
};


export type ExperienceQueryGroupCountArgs = {
  filters?: InputMaybe<ExperienceCountFiltersInput>;
};


export type ExperienceQueryGroupGetByIdArgs = {
  experienceId: Scalars['ExperienceId'];
};


export type ExperienceQueryGroupListArgs = {
  filters?: InputMaybe<ExperienceListFiltersInput>;
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<ExperienceSortDefinitionInput>;
};

export type ExperienceSortDefinitionInput = {
  experienceSortField: ExperienceSortField;
  sortDirection: SortDirection;
};

export enum ExperienceSortField {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME'
}

/** Happening */
export type Happening = {
  __typename?: 'Happening';
  /** Happening's identifier */
  id: Scalars['ID'];
  /** Happening's name */
  name: Scalars['String'];
  /** Happening's thumbnailUrl */
  thumbnailUrl: Scalars['String'];
  /** Happening's url */
  url: Scalars['String'];
};

/** Happening for admin description */
export type HappeningForAdmin = {
  __typename?: 'HappeningForAdmin';
  /** Happening's identifier */
  id: Scalars['ID'];
  /** Happening's last usage datetime */
  lastUsedAt?: Maybe<Scalars['Instant']>;
  /** Happening's name */
  name: Scalars['String'];
  /** Happening's number of uses */
  numberOfUses: Scalars['Int'];
  /** Happening's thumbnailUrl */
  thumbnailUrl: Scalars['String'];
  /** Happening's url */
  url: Scalars['String'];
};

/** Happening for admin list */
export type HappeningForAdminList = {
  __typename?: 'HappeningForAdminList';
  /** Items */
  items: Array<HappeningForAdmin>;
  /** Meta */
  meta: ListMetadata;
};

export type HappeningListForAdminFiltersInput = {
  searchTerm?: InputMaybe<Scalars['String']>;
  takeFrom?: InputMaybe<Scalars['Instant']>;
  takeTo?: InputMaybe<Scalars['Instant']>;
};

export type HappeningQueryGroup = {
  __typename?: 'HappeningQueryGroup';
  list: HappeningForAdminList;
};


export type HappeningQueryGroupListArgs = {
  filters?: InputMaybe<HappeningListForAdminFiltersInput>;
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<HappeningSortDefinitionInput>;
};

export type HappeningSortDefinitionInput = {
  happeningSortField: HappeningSortField;
  sortDirection: SortDirection;
};

export enum HappeningSortField {
  LastUsedAt = 'LAST_USED_AT',
  Name = 'NAME',
  NumberOfUses = 'NUMBER_OF_USES'
}

/** Identity details */
export type Identity = {
  __typename?: 'Identity';
  /** Email address */
  email: Scalars['String'];
  /** Email confirmed */
  emailConfirmed: Scalars['Boolean'];
  /** Identity unique identifier */
  id: Scalars['IdentityId'];
  /** Is banned */
  isBanned: Scalars['Boolean'];
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Profile picture url */
  profilePictureUrl?: Maybe<Scalars['String']>;
  /** Registered at */
  registeredAt: Scalars['Instant'];
};

export type IdentityCount = {
  __typename?: 'IdentityCount';
  /** number of registered identities */
  result: Scalars['Int'];
};

/** Identity list */
export type IdentityList = {
  __typename?: 'IdentityList';
  /** Items */
  items: Array<Identity>;
  /** Meta */
  meta: ListMetadata;
};

export type IdentityListFiltersInput = {
  emailConfirmed?: InputMaybe<Scalars['Boolean']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type IdentityMutationGroup = {
  __typename?: 'IdentityMutationGroup';
  ban: MutationResponse;
  delete: MutationResponse;
  login: AuthMutationResponse;
  refreshAccessToken: AuthMutationResponse;
  unban: MutationResponse;
};


export type IdentityMutationGroupBanArgs = {
  input: BanInput;
};


export type IdentityMutationGroupDeleteArgs = {
  input: DeleteInput;
};


export type IdentityMutationGroupLoginArgs = {
  input: LoginInput;
};


export type IdentityMutationGroupRefreshAccessTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};


export type IdentityMutationGroupUnbanArgs = {
  input: UnbanInput;
};

export type IdentityQueryGroup = {
  __typename?: 'IdentityQueryGroup';
  count: IdentityCount;
  getMyDetails: Identity;
  list: IdentityList;
};


export type IdentityQueryGroupListArgs = {
  filters?: InputMaybe<IdentityListFiltersInput>;
  meta?: InputMaybe<ListMetadataInput>;
  sortDefinition?: InputMaybe<IdentitySortDefinitionInput>;
};

export type IdentitySortDefinitionInput = {
  identitySortField: IdentitySortField;
  sortDirection: SortDirection;
};

export enum IdentitySortField {
  Email = 'EMAIL',
  Name = 'NAME',
  RegisteredAt = 'REGISTERED_AT'
}

export type ListMetadata = {
  __typename?: 'ListMetadata';
  nextPageCursor?: Maybe<Scalars['String']>;
  pageCursor?: Maybe<Scalars['String']>;
  pageSize: Scalars['Int'];
};

export type ListMetadataInput = {
  pageCursor?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  content: ContentMutationGroup;
  experience: ExperienceMutationGroup;
  identity: IdentityMutationGroup;
};

export type MutationIdResponse = {
  __typename?: 'MutationIdResponse';
  id: Scalars['ID'];
};

export type MutationResponse = {
  __typename?: 'MutationResponse';
  success: Scalars['Boolean'];
};

/** Poll description */
export type Poll = {
  __typename?: 'Poll';
  /** Id */
  id: Scalars['ID'];
  /** Owner id */
  ownerId: Scalars['ID'];
  /** Poll Options */
  pollOptions: Array<PollOption>;
};

/** PollOption description */
export type PollOption = {
  __typename?: 'PollOption';
  /** happening details */
  happening: Happening;
  /** Id */
  id: Scalars['ID'];
  /** Votes */
  votes: Array<Vote>;
};

export enum PublicStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type Query = {
  __typename?: 'Query';
  experience: ExperienceQueryGroup;
  happening: HappeningQueryGroup;
  identity: IdentityQueryGroup;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Status {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED'
}

/** Ticket details */
export type Ticket = {
  __typename?: 'Ticket';
  /** Ticket content */
  content: Scalars['String'];
  /** Holder details */
  holder: User;
  /** Holder id */
  holderId: Scalars['ID'];
};

export type UnbanInput = {
  identityId: Scalars['IdentityId'];
};

export type UpdateFrequentlyAskedQuestionsInput = {
  answer: Scalars['String'];
  id: Scalars['FrequentlyAskedQuestionId'];
  question: Scalars['String'];
};

/** User details */
export type User = {
  __typename?: 'User';
  /** Identity unique identifier */
  identityId: Scalars['IdentityId'];
  /** Full name */
  name?: Maybe<Scalars['String']>;
  /** Profile picture url */
  profilePictureUrl?: Maybe<Scalars['String']>;
};

/** Vote details */
export type Vote = {
  __typename?: 'Vote';
  /** Voter details */
  voter: User;
  /** Voter id */
  voterId: Scalars['ID'];
};

export type RefreshAccessTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshAccessTokenMutation = { __typename?: 'Mutation', identity: { __typename?: 'IdentityMutationGroup', refreshAccessToken: { __typename?: 'AuthMutationResponse', accessToken: string, refreshToken: string, chatUserToken: string, identity: { __typename?: 'Identity', id: any, email: string, registeredAt: any, name?: string | null, profilePictureUrl?: string | null, emailConfirmed: boolean, isBanned: boolean } } } };

export type DeleteExperienceMutationVariables = Exact<{
  input: ExperienceDeleteInput;
}>;


export type DeleteExperienceMutation = { __typename?: 'Mutation', experience: { __typename?: 'ExperienceMutationGroup', delete: { __typename?: 'MutationResponse', success: boolean } } };

export type ExperiencesQueryVariables = Exact<{
  sortDefinition?: InputMaybe<ExperienceSortDefinitionInput>;
  meta?: InputMaybe<ListMetadataInput>;
  filters?: InputMaybe<ExperienceListFiltersInput>;
}>;


export type ExperiencesQuery = { __typename?: 'Query', experience: { __typename?: 'ExperienceQueryGroup', list: { __typename?: 'ExperienceList', items: Array<{ __typename?: 'Experience', id: string, name: string, inviteCode: string, createdAt?: any | null, ownerId: string, status?: Status | null, poll?: { __typename?: 'Poll', id: string, ownerId: string, pollOptions: Array<{ __typename?: 'PollOption', id: string, votes: Array<{ __typename?: 'Vote', voterId: string, voter: { __typename?: 'User', identityId: any, name?: string | null, profilePictureUrl?: string | null } }>, happening: { __typename?: 'Happening', id: string, name: string, url: string, thumbnailUrl: string } }> } | null, activity?: { __typename?: 'Activity', id: string, name: string, url: string, thumbnailUrl: string, tickets: Array<{ __typename?: 'Ticket', holderId: string, content: string, holder: { __typename?: 'User', identityId: any, name?: string | null, profilePictureUrl?: string | null } }>, happening: { __typename?: 'Happening', id: string, name: string, url: string, thumbnailUrl: string } } | null, members: Array<{ __typename?: 'User', identityId: any, name?: string | null, profilePictureUrl?: string | null } | null> }>, meta: { __typename?: 'ListMetadata', pageSize: number, pageCursor?: string | null, nextPageCursor?: string | null } } } };

export type HappeningsQueryVariables = Exact<{
  sortDefinition?: InputMaybe<HappeningSortDefinitionInput>;
  meta?: InputMaybe<ListMetadataInput>;
  filters?: InputMaybe<HappeningListForAdminFiltersInput>;
}>;


export type HappeningsQuery = { __typename?: 'Query', happening: { __typename?: 'HappeningQueryGroup', list: { __typename?: 'HappeningForAdminList', items: Array<{ __typename?: 'HappeningForAdmin', id: string, name: string, url: string, thumbnailUrl: string, numberOfUses: number, lastUsedAt?: any | null }>, meta: { __typename?: 'ListMetadata', pageSize: number, pageCursor?: string | null, nextPageCursor?: string | null } } } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', identity: { __typename?: 'IdentityMutationGroup', login: { __typename?: 'AuthMutationResponse', accessToken: string, refreshToken: string } } };

export type BanUserMutationVariables = Exact<{
  input: BanInput;
}>;


export type BanUserMutation = { __typename?: 'Mutation', identity: { __typename?: 'IdentityMutationGroup', ban: { __typename?: 'MutationResponse', success: boolean } } };

export type DeleteUserMutationVariables = Exact<{
  input: DeleteInput;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', identity: { __typename?: 'IdentityMutationGroup', delete: { __typename?: 'MutationResponse', success: boolean } } };

export type UnbanUserMutationVariables = Exact<{
  input: UnbanInput;
}>;


export type UnbanUserMutation = { __typename?: 'Mutation', identity: { __typename?: 'IdentityMutationGroup', unban: { __typename?: 'MutationResponse', success: boolean } } };

export type UsersQueryVariables = Exact<{
  sortDefinition?: InputMaybe<IdentitySortDefinitionInput>;
  filters?: InputMaybe<IdentityListFiltersInput>;
  meta?: InputMaybe<ListMetadataInput>;
}>;


export type UsersQuery = { __typename?: 'Query', identity: { __typename?: 'IdentityQueryGroup', list: { __typename?: 'IdentityList', meta: { __typename?: 'ListMetadata', pageSize: number, nextPageCursor?: string | null, pageCursor?: string | null }, items: Array<{ __typename?: 'Identity', id: any, email: string, name?: string | null, emailConfirmed: boolean, isBanned: boolean, registeredAt: any }> } } };

export const RefreshAccessTokenDocument = gql`
    mutation RefreshAccessToken($refreshToken: String!) {
  identity {
    refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
      chatUserToken
      identity {
        id
        email
        registeredAt
        name
        profilePictureUrl
        emailConfirmed
        isBanned
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RefreshAccessTokenGQL extends Apollo.Mutation<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables> {
    document = RefreshAccessTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteExperienceDocument = gql`
    mutation DeleteExperience($input: ExperienceDeleteInput!) {
  experience {
    delete(input: $input) {
      success
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteExperienceGQL extends Apollo.Mutation<DeleteExperienceMutation, DeleteExperienceMutationVariables> {
    document = DeleteExperienceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExperiencesDocument = gql`
    query Experiences($sortDefinition: ExperienceSortDefinitionInput, $meta: ListMetadataInput, $filters: ExperienceListFiltersInput) {
  experience {
    list(sortDefinition: $sortDefinition, filters: $filters, meta: $meta) {
      items {
        id
        name
        inviteCode
        createdAt
        ownerId
        status
        poll {
          id
          ownerId
          pollOptions {
            id
            votes {
              voterId
              voter {
                identityId
                name
                profilePictureUrl
              }
            }
            happening {
              id
              name
              url
              thumbnailUrl
            }
          }
        }
        activity {
          id
          name
          tickets {
            holderId
            content
            holder {
              identityId
              name
              profilePictureUrl
            }
          }
          url
          thumbnailUrl
          happening {
            id
            name
            url
            thumbnailUrl
          }
        }
        members {
          identityId
          name
          profilePictureUrl
        }
      }
      meta {
        pageSize
        pageCursor
        nextPageCursor
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExperiencesGQL extends Apollo.Query<ExperiencesQuery, ExperiencesQueryVariables> {
    document = ExperiencesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HappeningsDocument = gql`
    query Happenings($sortDefinition: HappeningSortDefinitionInput, $meta: ListMetadataInput, $filters: HappeningListForAdminFiltersInput) {
  happening {
    list(sortDefinition: $sortDefinition, meta: $meta, filters: $filters) {
      items {
        id
        name
        url
        thumbnailUrl
        numberOfUses
        lastUsedAt
      }
      meta {
        pageSize
        pageCursor
        nextPageCursor
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HappeningsGQL extends Apollo.Query<HappeningsQuery, HappeningsQueryVariables> {
    document = HappeningsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  identity {
    login(input: {email: $email, password: $password}) {
      accessToken
      refreshToken
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BanUserDocument = gql`
    mutation BanUser($input: BanInput!) {
  identity {
    ban(input: $input) {
      success
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BanUserGQL extends Apollo.Mutation<BanUserMutation, BanUserMutationVariables> {
    document = BanUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteUserDocument = gql`
    mutation DeleteUser($input: DeleteInput!) {
  identity {
    delete(input: $input) {
      success
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnbanUserDocument = gql`
    mutation UnbanUser($input: UnbanInput!) {
  identity {
    unban(input: $input) {
      success
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnbanUserGQL extends Apollo.Mutation<UnbanUserMutation, UnbanUserMutationVariables> {
    document = UnbanUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query Users($sortDefinition: IdentitySortDefinitionInput, $filters: IdentityListFiltersInput, $meta: ListMetadataInput) {
  identity {
    list(sortDefinition: $sortDefinition, filters: $filters, meta: $meta) {
      meta {
        pageSize
        nextPageCursor
        pageCursor
      }
      items {
        id
        email
        name
        emailConfirmed
        isBanned
        registeredAt
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
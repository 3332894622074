import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay, tap } from "rxjs";
import { LoginGQL, LoginMutation, RefreshAccessTokenGQL, RefreshAccessTokenMutation } from "@/core/graphql/graphql";
import { Router } from "@angular/router";
import { MutationResult } from 'apollo-angular';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _loggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly loggedIn$: Observable<boolean> = this._loggedInSubject.asObservable();

  constructor(
    private readonly _loginGQL: LoginGQL,
    private readonly _refreshTokenGQL: RefreshAccessTokenGQL,
    private readonly _router: Router,
  ) {
  }

  public login$(email: string, password: string): Observable<MutationResult<LoginMutation>> {
    return this._loginGQL.mutate({ email, password })
      .pipe(
        tap(({ data }) => {
          this.setSession(data!.identity.login.accessToken);
          this.setRefreshToken(data!.identity.login.refreshToken);
          this._loggedInSubject.next(true);
        }),
        shareReplay()
      )
  }

  public logout(): void {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    this._loggedInSubject.next(false);
    this._router.navigate(["login"]);
  }

  public isLoggedIn(): boolean {
    return !!this.getAuthToken();
  }

  public getAuthToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  public refreshToken$(): Observable<MutationResult<RefreshAccessTokenMutation>> {
    return this._refreshTokenGQL.mutate({
      refreshToken: localStorage.getItem('refresh_token')!
    }).pipe(
      tap( next => {
        this.setSession(next.data?.identity.refreshAccessToken.accessToken!);
        this.setRefreshToken(next.data?.identity.refreshAccessToken.refreshToken!);
      })
    );
  }

  private setSession(accessToken: string): void {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
  }

  private setRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }
}

import { MatInputModule } from '@angular/material/input';
import { LoginComponent } from './login.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from "@angular/router";
import { MatButtonModule } from "@angular/material/button";

const routes: Routes = [{
  path: '',
  component: LoginComponent,
}];

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    MatButtonModule
  ]
})
export class LoginModule { }

import { AppComponent } from "./app.component";
import { BrowserModule } from "@angular/platform-browser";
import { CoreModule } from "./core/core.module";
import { GraphQLModule } from "./core/graphql/graphql.module";
import { HttpClientModule } from "@angular/common/http";
import { LoginModule } from "./modules/login/login.module";
import { MatRippleModule } from "@angular/material/core";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    NoopAnimationsModule,
    MatRippleModule,
    LoginModule,
    GraphQLModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  exports: [],
  bootstrap: [AppComponent]
})

export class AppModule {}

import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AuthService } from "@/core/services/auth.service";
import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "cms-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  public _loginForm: UntypedFormGroup;
  public _emailFormControl: UntypedFormControl;
  public _passwordFormControl: UntypedFormControl;

  constructor(
    private readonly _fb: UntypedFormBuilder,
    private readonly _authService: AuthService,
    private readonly _router: Router
  ) {
    this._emailFormControl = this._fb.control("", [
      Validators.required,
      Validators.email,
    ]);
    this._passwordFormControl = this._fb.control("", [Validators.required]);
    this._loginForm = this._fb.group({
      email: this._emailFormControl,
      password: this._passwordFormControl,
    });
  }

  public onLogin(): void {
    const formData = this._loginForm.getRawValue();
    this._authService
      .login$(formData.email, formData.password)
      .subscribe((_) => {
        this._router.navigate([""]);
      });
  }
}
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";

export enum MessageType {
    Error = 'toast-error',
    Success = 'toast-success',
    Warning = 'toast-warning'
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
    private readonly matSnackBarHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
    private readonly matSnackBarVerticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
      private readonly _snackbar: MatSnackBar
  ) {
  }

  public raiseMessage(content: string, messageType: MessageType): void {
    this._snackbar.open(
      content,
      'OK',
      {
        duration: 3000,
        horizontalPosition: this.matSnackBarHorizontalPosition,
        verticalPosition: this.matSnackBarVerticalPosition,
        panelClass: messageType
      }
    )
  }
}
